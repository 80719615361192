<template>
  <div>
    <base-template
        v-if="isReady"
        ref="baseTemplate"
        :title="$tc('SupplierReceivable',2)"
        :items="incomingInvoices2"
        :items-context="incomingInvoicesContext"
        :available-filters="$store.getters['incomingInvoice2/getAvailableFilters']"
        :payload.sync="payload"
        :module="module"
        :is-list-view-action-column-enabled="true"

        :is-button-add-disabled="!selectedItems.length"
        list-view-selectable="multi"
        :is-view-windows-ready="displayedSupplierInvoice!=null"

        @addItem="addPayment(selectedItems)"
        @selectedItems="selectedItems = $event"
        @showDetails="showDetails"
    >
      <template #listView_cell__reconciliationStatus="{item}">
        <b-badge
            v-if="item._reconciliationStatus != null"
            pill
            :variant="getBadgeColorReconciliationStatus(item)"
        >
          <span v-if="item._reconciliationStatus == 'Unlettered'">
            {{
              $tc('Unlettered:',
                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),
                  {
                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),
                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')
                  }
              )
            }}
          </span>
          <span v-else>
            {{ $t(item._reconciliationStatus) }}
          </span>
        </b-badge>
      </template>

      <template #listView_cell__bankReconciliationStatus="{item}">
        <b-badge
            v-if="item._bankReconciliationStatus != null"
            pill
            :variant="getBadgeColorBankReconciliationStatus(item)"
        >
          <span v-if="item._bankReconciliationStatus == 'Unpaid'">
            {{
              $tc('Unpaid:',
                  getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))),
                  {
                    days: Math.abs(getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate)))),
                    suffix: getDaysRemaining(item._dueDate ? item._dueDate : (item.dueDate ? item.dueDate : (item.month ? item.month : item.paymentDate))) >= 0 ? $t('remaining') : $t('ofLate')
                  }
              )
            }}
          </span>
          <span v-else>
            {{ $t(item._bankReconciliationStatus) }}
          </span>
        </b-badge>
      </template>

      <template #listView_cell_actions="{item}">
        <!--          Download-->
        <button-download
            v-b-tooltip.hover.left="$t('Download')"
            @click.native.stop="downloadIncomingInvoice(item)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Payment-->
        <button-payment
            @click.native.stop="addPayment([item])"
            v-b-tooltip.hover.left="capitalize($t('addPayment'))"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

      </template>

      <template #leftViewWindow="{item}">
        <supplier-invoice-card
            :incoming-invoice="item"
            :is-viewable="true"
            :is-selected="item.id==displayedSupplierInvoice.id"
            :is-edit-allowed="false"
            :is-trash-allowed="false"

            @click="showDetails(item)"
            @view="showDetails(item)"
            @pay="addPayment([item])"
        />
      </template>
      <template #viewWindowTitle>
        {{ displayedSupplierInvoice.billNumber }} -
        {{ displayedSupplierInvoice.supplierCompany ? displayedSupplierInvoice.supplierCompany._display : '' }}
      </template>
      <template #viewWindowButtons>
        <!--          Download-->
        <button-download
            v-b-tooltip.hover.left="$t('Download')"
            @click.native.stop="downloadIncomingInvoice(displayedSupplierInvoice)"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />

        <!--          Payment-->
        <button-payment
            @click.native.stop="addPayment([displayedSupplierInvoice])"
            v-b-tooltip.hover.left="capitalize($t('addPayment'))"
            :withIcon="true"
            :withText="false"
            :withBorder="false"
            size="sm"
        />
      </template>
      <template #windowView>
        <b-alert
            show
            :variant="incomingInvoicePaymentBannerColor(displayedSupplierInvoice)"
        >
          <div class="alert-body d-flex justify-content-between">
            <icon
                icon="cash-register"
                class="top-0 my-auto"
            />
            <span class="ml-50 w-100 my-auto">
              <span v-if="incomingInvoicePaymentBannerColor(displayedSupplierInvoice) == 'danger'">
                {{ $t('The payment of this supplier invoice is late') }}
              </span>
              <span v-else-if="incomingInvoicePaymentBannerColor(displayedSupplierInvoice) == 'warning'">
                {{ $t('A partial payment has been recorded for this supplier invoice') }}
              </span>
              <span v-else>
                {{ $t('This supplier invoice is in Open status') }}
              </span>
            </span>
            <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                size="sm"
                class="text-nowrap"
                :variant="'outline-'+incomingInvoicePaymentBannerColor(displayedSupplierInvoice)"
                @click="addPayment([displayedSupplierInvoice])"
            >
              {{ $t('addPayment') }}
            </b-button>
          </div>
        </b-alert>

        <supplier-invoice-view :supplier-invoice="displayedSupplierInvoice"/>
      </template>
    </base-template>


    <modal-payment
        :payment.sync="currentPayment"
        :title="'id' in currentPayment?$t('EditPayment'):$t('NewPayment')"
        :isOpen.sync="paymentModalShow"

        @submitValidated="submitValidatedPaymentLocal"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize} from '../../../utils/filter'
import { usePayments } from '../usePayment'

import useAPI from '../../../utils/useAPI'
import store from '../../../store'
import moment from 'moment'

import BaseTemplate from '../../../components/base3/Base.vue'
import SupplierInvoiceView from '../../../components/views/SupplierInvoiceView.vue'
import ButtonDownload from '../../../components/button/Download.vue'
import ButtonPayment from '../../../components/button/Payment.vue'
import SupplierInvoiceCard from '../../../components/card/IncomingInvoice2.vue'
import ModalPayment from '../../../components/prompt/Payment2.vue'
import Ripple from 'vue-ripple-directive'

export default {
  directives: {
    Ripple,
  },
  components: {
    ModalPayment,
    SupplierInvoiceCard,
    ButtonPayment,
    ButtonDownload,
    SupplierInvoiceView,
    BaseTemplate
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const baseTemplate= ref()
    const module = ref('supplierReceivables')
    const payload = ref({})
    const isReady = ref(false)
    const isAutocompleteLoaded = ref(false)

    const displayedSupplierInvoice = ref(null)
    const selectedItems = ref([])
    const currentPayment = ref({})
    const paymentModalShow = ref(false)


    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { incomingInvoices2, incomingInvoicesContext } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(payload, val => {
      isReady.value = true
      fetchIncomingInvoices2(val)
          .then(() => {
            if (!isAutocompleteLoaded.value) {
              loadInit()
            }
          })
    }, { deep: true })

    watch(paymentModalShow, val => {
      if (val == false) {
        resetPayment()
      }
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchIncomingInvoices2,
      fetchAutocompletePaymentMethods,
      fetchAutocompleteBankAccounts,
    } = useAPI()

    const { submitValidatedPayment } = usePayments()

    const loadInit = () => {
      fetchAutocompletePaymentMethods()
      fetchAutocompleteBankAccounts()
      resetPayment()
      isAutocompleteLoaded.value=true
    }

    const resetPayment = () => {
      selectedItems.value = []
      baseTemplate.value.listViewRef.resetSelected()
      currentPayment.value = {}
    }

    const showDetails = (supplierInvoice) => {
      if (supplierInvoice != null) {
        store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoice.id)
            .then(response => {
              displayedSupplierInvoice.value = response
            })

      } else {
        displayedSupplierInvoice.value = null
      }
    }

    const addPayment = (supplierInvoices) => {
      if (supplierInvoices.length) {
        let amount = 0
        let paymentRelations = []
        supplierInvoices.forEach(ii => {
          amount += parseFloat(ii._balance)
          paymentRelations.push({
            amount: parseFloat(ii._balance),
            incomingInvoice: ii
          })
        })

        store.dispatch('incomingInvoice2/getIncomingInvoice', supplierInvoices[0].id)
            .then(response => {
              currentPayment.value = {
                paymentMethod: response.paymentMethod,
                date: (new Date()).toISOString().slice(0, 10),
                paymentRelations: paymentRelations,
                bankAccount: response.bankAccount
              }

              paymentModalShow.value = true
            })
      }
    }

    const submitValidatedPaymentLocal = () => {
      submitValidatedPayment(currentPayment.value)
          .then(response => {
            paymentModalShow.value = false
          })
    }

    const downloadIncomingInvoice = (incomingInvoice) => {
      // console.log(incomingInvoice)
      if (incomingInvoice.receipt != null) {
        let link = document.createElement('a')
        link.href = incomingInvoice.receipt.url
        link.target = "_blank"
        link.download = incomingInvoice.receipt.name + '.' + incomingInvoice.receipt.extension
        link.click()
      }
    }

    const getBadgeColorReconciliationStatus = (item) => {
      if (item._reconciliationStatus == 'Lettered') {
        return 'success'
      } else if (item._reconciliationStatus == 'Partially lettered') {
        return 'warning'
      } else if (item._reconciliationStatus == 'Unlettered') {
        return 'danger'
      }
    }

    const getBadgeColorBankReconciliationStatus = (item) => {
      if (item._bankReconciliationStatus == 'Paid') {
        return 'success'
      } else if (item._bankReconciliationStatus == 'Partially paid') {
        return 'warning'
      } else if (item._bankReconciliationStatus == 'Unpaid') {
        return 'danger'
      }
    }

    const getDaysRemaining = (dueDate) => {
      return parseInt(moment.duration(moment(dueDate).diff(moment().startOf('day'))).asDays())
    }

    const incomingInvoicePaymentBannerColor = (incomingInvoice) => {
      if (incomingInvoice._paymentStatus == 'Waiting for payment') {
        return 'secondary'
      } else if (incomingInvoice._paymentStatus == 'Partial payment') {
        if (moment() > moment(incomingInvoice.billingDate)) {
          return 'danger'
        } else {
          return 'warning'
        }
      } else {
        return 'danger'
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    store.dispatch('moduleView/getModuleViews')
        .then((response) => {
          let currentView = store.getters['moduleView/getCurrentModuleView'](module.value)

          payload.value = {
            filters: currentView.filters,
            kanbanSortBy: currentView.kanbanSortBy,
            kanbanColumnAmount: currentView.kanbanColumnAmount,
            page: 1,
            sorts: currentView.sorts,
          }

        })

    return {
      // Components
      capitalize,

      // Data
      baseTemplate,
      module,
      payload,
      isReady,

      displayedSupplierInvoice,
      selectedItems,
      currentPayment,
      paymentModalShow,

      // Computed
      incomingInvoices2,
      incomingInvoicesContext,

      // Methods
      submitValidatedPayment,
      showDetails,
      addPayment,
      submitValidatedPaymentLocal,
      downloadIncomingInvoice,
      getBadgeColorReconciliationStatus,
      getBadgeColorBankReconciliationStatus,
      getDaysRemaining,
      incomingInvoicePaymentBannerColor,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>